<template>
  <section class="container-fluid mt-4 pso-dashboard announcement">
    <div class="box">
      <div class="box-header d-flex justify-content-between bg-white">
        <div class="">
          แก้ไขคำแนะนำ
          <span v-if="isDirty" class="text-danger">(ยังไม่ได้บันทึก)</span>
        </div>
        <div class="d-flex align-items-center">
          <div class="text-light-ipepp mr-3">
            Last modified: {{ nationalPlan.updated_at | thai_date(true) }}
          </div>
          <button
            v-promise-btn
            class="btn btn-action py-1 px-4"
            @click="save"
            v-if="canEditCurrentNationalPlan"
          >
            บันทึก
          </button>
        </div>
      </div>
      <trumbowyg
        ref="editor"
        v-model="credentials.target_guideline"
        :config="config"
        class="box-body"
        placeholder="รายละเอียด"
      ></trumbowyg>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
import Form from '../../modules/form/form';
import { required } from 'vuelidate/lib/validators';
import nationalPlanChangedListener from '../../mixins/nationalPlanChangedListener';

export default {
  name: 'TargetGuideline',

  mixins: [nationalPlanChangedListener],

  components: {
    Trumbowyg,
  },

  data() {
    return {
      ready: false,
      credentials: new Form({
        data: () => {
          return {
            target_guideline: '',
            config: {
              resetAfterSuccess: false,
            },
          };
        },
        validations: {
          target_guideline: {
            required,
          },
        },
      }),
      config: {
        semantic: false,
        btns: [
          ['viewHTML'],
          ['undo', 'redo'], // Only supported in Blink browsers
          ['formatting'],
          ['fontsize'],
          ['fontfamily'],
          ['bold', 'underline', 'italic', 'strike'],
          ['superscript', 'subscript'],
          ['link'],
          ['insertImage'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat'],
          ['fullscreen'],
        ],
        plugins: {
          fontfamily: {
            fontList: this.$fontfamilyList,
          },
        },
        // Get options from
        // https://alex-d.github.io/Trumbowyg/documentation
      },
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters([
      'currentNationalPlan',
      'latestNationalPlan',
      'canEditCurrentNationalPlan',
    ]),
    nationalPlan() {
      return this.currentNationalPlan;
    },
    isDirty() {
      return (
        this.credentials.target_guideline !== this.nationalPlan.target_guideline
      );
    },
  },
  methods: {
    init() {
      this.credentials.target_guideline = this.nationalPlan.target_guideline;
    },
    currentNationalPlanChanged() {
      this.init();
      if (this.canEditCurrentNationalPlan) {
        this.$refs.editor.el.trumbowyg('enable');
      } else {
        this.$refs.editor.el.trumbowyg('disable');
      }
    },
    save() {
      return this.credentials
        .patch(
          '/api/national-plans/' + this.nationalPlan.id + '/target_guideline'
        )
        .then((data) => {
          this.$store.commit('setNationalPlan', data);
        })
        .catch((error) => {
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.announcement {
  .trumbowyg-box {
    margin-top: 0px !important;
  }
}
</style>
